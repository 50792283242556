import React from 'react';
import styled from 'react-emotion';
import { Link, navigate,graphql,useStaticQuery } from 'gatsby';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import { connect } from 'react-redux';

import shieldImage from '../../../static/images/shield.svg';

const ProductRow = styled.div`
  ${tw`flex mb-8 relative`};
`
const ImageColumn = styled.div`
  ${tw`w-1/6 flex flex-col items-center justify-center pr-3 cursor-pointer`};
  flex-grow: 0;
`
const ProductImage = styled.div`
  ${tw`w-full h-0 m-0`}
  background-image: url(${props => (props.src)});
  padding-top: 100%;
  background-size: cover;
  background-position-x: center;
`
const DetailColumn = styled.div`
  ${tw`w-5/6 flex flex-col justify-center cursor-pointer`};
`
const ProductTitle = styled.div`
  ${tw`text-base`};
`
const ProductPriceContainer = styled.div`
  ${tw`text-sm flex`};
  color: grey;
  font-weight: 300;
`
const Price = styled.div`
  ${tw`pr-1`};
`
const Quantity = styled.div`
  ${tw`text-xs pr-2`};
`
const TotalPrice = styled.div`
  ${tw`font-bold`};
  margin-right:5px;
`
const TotalPriceGrow = styled.div`
  flex-grow:1;
`
const SelectNum = styled.select`
  margin-left:5;
`
const DateFlex = styled.div`
  ${tw`flex`};
`
const DateContainer = styled.div`
  ${tw`w-2/5 text-sm`};
  color: grey;
`
const DateTitle = styled.div`
  ${tw`h-4`};
  font-size: 0.65rem;
`
const DeleteButton = styled.svg`
  ${tw`absolute pin-r pin-b w-3`};
  cursor: pointer;
`
const ProductList = (props) => {
  const data = useStaticQuery(graphql`
  query {
    settings{
      data{
        deposit
      }
    }
  }
`)
  return (
    <div>
      {props.products.map((product) => (
          <ProductRow key={product.key}>
            <ImageColumn onClick={() => navigate(`/products/detail?id=${product.id}`)}>
              <ProductImage src={product.thumbs[0].url} alt={product.thumbs[0].alt}/>
            </ImageColumn>
            <DetailColumn onClick={() => navigate(`/products/detail?id=${product.id}`)}>
              <ProductTitle>
                {product.title}
              </ProductTitle>
              <ProductPriceContainer>
                <Price>
                  <CurrencyFormat
                    value={props.genDisc?product.price:product.discountPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                  />  
                </Price>
                <Quantity>
                  {`x${product.quantity}`}
                </Quantity>
                <TotalPrice>
                  <CurrencyFormat
                    value={(props.genDisc?product.price:product.discountPrice)*product.quantity}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                  />
                </TotalPrice>
              </ProductPriceContainer>
              {
                product.type === 'wifi' ? (
                  <ProductPriceContainer>
                    <TotalPrice>
                      <CurrencyFormat
                        value={(props.genDisc?product.price:product.discountPrice)*product.quantity * props.numberOfDevices}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                      />
                    </TotalPrice>
                    <Quantity>
                      {`(${props.numberOfDevices} devices)`}
                    </Quantity>
                  </ProductPriceContainer>
                ) : null
              }
              <DateFlex>
                {
                  (product.type === 'reseller') ? null : (
                    <>
                      <DateContainer>
                        <DateTitle>Start date</DateTitle>
                        <div>{moment(product.startDate).format('DD/MM/YYYY')}</div>
                      </DateContainer>
                      <DateContainer>
                        <DateTitle>End date</DateTitle>
                        <div>{moment(product.lastDate).format('DD/MM/YYYY')}</div>
                      </DateContainer>
                    </>
                  )
                }
              </DateFlex>
            </DetailColumn>
            <DeleteButton
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              onClick={() => props.deleteProduct(product.key)}
            >
              <path fill="currentColor" d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm415.2 56.7L394.8 467c-1.6 25.3-22.6 45-47.9 45H101.1c-25.3 0-46.3-19.7-47.9-45L32.8 140.7c-.4-6.9 5.1-12.7 12-12.7h358.5c6.8 0 12.3 5.8 11.9 12.7z"></path>
            </DeleteButton>
          </ProductRow>
      ))}

      {
        (props.products.filter((p) => p.type === 'wifi').length === 0) ? null :
        <ProductRow>
          <ImageColumn>
            <ProductImage src={shieldImage} />
            
          </ImageColumn>
          <DetailColumn>
            <ProductTitle>
              {data.settings.data.deposit>0 ? 'Deposit Jaminan Peminjaman Device' : 'Wifi Device'}
            </ProductTitle>
            <ProductPriceContainer>

              <TotalPriceGrow>
                <TotalPrice>
                  {data.settings.data.deposit>0?
                  <CurrencyFormat
                    value={data.settings.data.deposit * props.numberOfDevices}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                  />:null}
                </TotalPrice>
              </TotalPriceGrow>
              <TotalPriceGrow>
                Jumlah Device:
                <SelectNum style={{ marginLeft: '5px' }} value={ props.numberOfDevices } onChange={(event) => props.changeNumberOfDevices(event.target.value)}>
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                      <option key={num} value={num}>{num}</option>
                    ))
                  }
                </SelectNum>
              </TotalPriceGrow>
            </ProductPriceContainer>
          </DetailColumn>
        </ProductRow>
      }
      
    </div>
  );
}

const mapStateToProps = state => ({
  numberOfDevices: state.numberOfDevices,
  deposit: state
});

const mapDispatchToProps = dispatch => ({
  changeNumberOfDevices: (qty) => dispatch({ type: 'CHANGE_NUMBER_OF_DEVICES', qty }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);